import axios from "axios";
import config from './utils/config.json';

function getConfiguration(){
  let env = window.location.hostname;
  let envConfig = {};

  if(env.includes("test")){
    envConfig = config["test"];
  }
  else if(env.includes("qa")){
    envConfig = config["qa"];
  }
  else if(env.includes("prod")){
    envConfig = config["prod"];
  }
  else
    envConfig = config["localhost"];

  return envConfig;
}

export let EnvConfigs = getConfiguration();

export default axios.create({
  baseURL: EnvConfigs['apiUrl'],
  headers: {
    "Content-type": "application/json",
  }
});