import React from 'react';
import ReactDOM from 'react-dom';
import { AuthProvider } from "react-oidc-context";
import App from './App';
import { EnvConfigs } from "./http-common";
import reportWebVitals from './reportWebVitals';

const oidcConfig = {
	authority: EnvConfigs['authority'],
	client_id: EnvConfigs['client_id'],
	redirect_uri: EnvConfigs['redirect_uri']
}

ReactDOM.render(
	<React.StrictMode>
		<AuthProvider {...oidcConfig}>
			<App />
		</AuthProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
