import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import CarriersList from "../Carriers/CarriersList";
import "./Home.scss";

const Home: React.FC = () => {
	return (
		<div>
			<nav>
				<div className="home">
					<Link to="flightranges" className="label-sm link-style-body">Flight Ranges</Link><br />
					<Link to={"/carriers"} className="label-sm link-style-body">Carriers</Link>
				</div>
				</nav>
				<div className="route">
					<Routes>
						<Route path="/carriers" element={<CarriersList/>} />
					</Routes>
				</div>
		</div>
	);
}

export default Home;