import React from "react";
import { Link } from "react-router-dom";
import "./Header.scss";
import { useAuth } from "react-oidc-context";

const Header = () => {

	const auth = useAuth();

	const isLoggedIn = auth.isAuthenticated;

	return (
		<div className="app-header">
			<img className="logo" alt="Alaska Airlines" src="https://resource.alaskaair.net/-/media/A2B28D27C57B4BB9A72557221CF779A3"></img>
			<span className="line"></span>
			<span className="bottom"><Link to="/" className="auro_heading auro_heading--800 link-style main">Scheduling System</Link></span>
			{isLoggedIn ?
				<label onClick={() => auth.removeUser()} className="label-sm link-style logout">Logout</label> :
				<label onClick={() => auth.signinRedirect()} className="label-sm link-style login">Login</label>}
			<label className="label-sm link-style help">Help</label>			
		</div>
	);
}

export default Header;


