import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import CarriersList from './Components/Carriers/CarriersList';
import { useAuth } from "react-oidc-context";

const App = () => {
	const auth = useAuth();

	if (auth.isLoading) {
		return <div>Loading...</div>;
	}

	if (auth.error) {
		return <div>Oops... {auth.error.message}</div>;
	}

	if(auth.isAuthenticated) {
		return (
			<React.StrictMode>
				<Router>
					<Header />
					<Routes>
						<Route path="*" element={<Home/>}/>
						<Route path="/carriers" element={<CarriersList/>} />
					</Routes>
				</Router>
			</React.StrictMode>
		);
	}

	return (
		<React.StrictMode>
			<Router>
				<Header />
			</Router>
		</React.StrictMode>
	);
}

export default App;
