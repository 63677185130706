import React, { useState, useEffect } from "react";
import CarrierDataService from "../../services/CarrierService";
import ICarrierData from '../../types/Carriers';
import './CarriersList.scss';
import { useAuth } from "react-oidc-context";

const CarriersList: React.FC = () => {
	const auth = useAuth();
	const [carriers, setCarriers] = useState<Array<ICarrierData>>([]);

	React.useEffect(() => {
		(async () => {
			try{
				const token = auth.user?.access_token;
				await CarrierDataService.getAll(token)
				.then((response: any) => {
					setCarriers(response.data);
					console.log(response.data);
				});

			} catch (e) {
				console.error(e);
			}
		})();
	}, [auth]);

	return (
		<div className="carriersList">
			<h4 className="label-sm link-style-body">Current list of carriers:</h4>
			<br></br>
			<ul className="no-bullets">
				{carriers && carriers.map((carrier, index) => (
						<li className="label-sm link-style-body" key={index}>{carrier.carrierName}({carrier.carrierCode})</li>
				))}
			</ul>
	</div>
	);
};

export default CarriersList;