import http from "../http-common";
import ICarrierData from "../types/Carriers";

const getAll = (token) => {
  return http.get<Array<ICarrierData>>('/Carrier',{
    headers:{
      Authorization: `Bearer ${token}`,
    }
  })
};

const CarrierService = {
  getAll,
};

export default CarrierService;